<template>
    <div class="declarations-id flex-2 flex flex__column">
        <page-header
            :branding="!adminRoute"
            title="Declaration details #12226893184806"
            include-back
        >
        </page-header>

        <div class="flex w-full flex-2">
            <div class="w-full flex flex__column declarations-id__right-content">
                <div class="flex">
                    <card class="w__50-p">
                        <div class="card-content border__bottom-grey w-full">
                            <div class="flex flex__align-center">
                                <button class="icon-btn icon-btn-blue cursor-default">
                                    <fa :icon="['fal','clipboard-list']" />
                                </button>

                                <span class="f-size-16 ml-2">General Details</span>
                            </div>
                        </div>

                        <div class="card-content flex flex__justify-between flex__wrap">
                            <div class="mb-4 w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">Customs id</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">ABCDE1234</div>
                            </div>

                            <div class="mb-4 w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">Lorem ipsum</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>

                            <div class="mb-4 w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">importer</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>

                            <div class="mb-4 w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">Lorem ipsum</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>

                            <div class="w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">exporter</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>

                            <div class="w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">Lorem ipsum</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>
                        </div>
                    </card>

                    <card class="w__50-p ml-2">
                        <div class="card-content border__bottom-grey  w-full">
                            <div class="flex flex__align-center">
                                <button class="icon-btn icon-btn-blue cursor-default">
                                    <fa :icon="['fal','box']" />
                                </button>

                                <span class="f-size-16 ml-2">Shipment Details</span>
                            </div>
                        </div>

                        <div class="card-content flex flex__justify-between flex__wrap">
                            <div class="mb-4 w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">Shipments #</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">ABCDE1234</div>
                            </div>

                            <div class="mb-4 w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">Lorem ipsum</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>

                            <div class="mb-4 w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">From</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>

                            <div class="mb-4 w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">Lorem ipsum</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>

                            <div class="w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">To</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>

                            <div class="w__50-p pr-4">
                                <div class="f-s-10 color-grey uppercase">Lorem ipsum</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">lorem ipsum</div>
                            </div>
                        </div>
                    </card>
                </div>
                
                <card class="mt-2 flex-2">
                    <table class="table">
                        <thead>
                            <td class="no-wrap pr-4">#</td>
                            <td class="no-wrap pr-4">HS-Code</td> 
                            <td class="no-wrap pr-4">Description</td> 
                            <td class="no-wrap pr-4">Pack kind</td>
                        </thead>

                        <tbody>
                            <tr v-for="item in 3" :key="item" class="static">
                                <td class="no-wrap pr-4">1</td>
                                <td class="no-wrap pr-4">SKU_0001</td>
                                <td class="no-wrap pr-4">Lorem ipsum</td>
                                <td class="no-wrap pr-4">RuD987654-321</td>
                            </tr>
                        </tbody>
                    </table>
                </card>
            </div>

            <div class="w-80 ml-2 flex">
                <card class="flex-2">
                    <div class="sh-d-additional__item pt-3 pl-6 pr-6 pb-2 flex flex__column flex__grow border-none">
                        <div class="flex flex__align-center sh-d-additional__head mb-6">
                            <span class="f-size-16 mr-2">Attached documents</span>
                        </div>

                        <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2" v-if="!documents.length">No attached files</div>

                        <div>
                            <template v-for="(item, i) in documents">
                                <div :key="i" v-if="item.media" class="mb-3 doc-icon flex flex__align-center flex__justify-between color-blue f-size-14 cursor-pointer">
                                    <div class="flex flex__align-center">
                                        <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.0417 6.09359V1.6665H4.53125C4.09831 1.6665 3.75 2.01481 3.75 2.44775V17.5519C3.75 17.9849 4.09831 18.3332 4.53125 18.3332H15.4688C15.9017 18.3332 16.25 17.9849 16.25 17.5519V6.87484H11.8229C11.3932 6.87484 11.0417 6.52327 11.0417 6.09359ZM13.5303 12.9738L10.3916 16.089C10.1751 16.3042 9.82552 16.3042 9.60905 16.089L6.47038 12.9738C6.13997 12.646 6.37174 12.0832 6.83659 12.0832H8.95833V9.479C8.95833 9.19124 9.19141 8.95817 9.47917 8.95817H10.5208C10.8086 8.95817 11.0417 9.19124 11.0417 9.479V12.0832H13.1634C13.6283 12.0832 13.86 12.646 13.5303 12.9738ZM16.0221 5.08447L12.8353 1.89437C12.6888 1.74788 12.4902 1.6665 12.2819 1.6665H12.0833V5.83317H16.25V5.6346C16.25 5.42953 16.1686 5.23096 16.0221 5.08447Z" fill="#00A3FF"/>
                                        </svg>

                                        <router-link :to="`/documents/${item.id}`" target="_blank" class="break-words color-blue">
                                            {{ item.name }}
                                        </router-link>
                                    </div>

                                    <div class="flex ml-2">
                                        <fa :icon="['fal', 'arrow-to-bottom']" @click="download(item)" />
                                        <fa :icon="['fal', 'trash-alt']" @click="deleteDocument(item)" class="ml-2" />
                                    </div>
                                </div>
                            </template>
                        </div>

                        <file-input @update="addDocument" class="mb-2" />

                        <div v-show="documents.length > 1" class="f-size-14 color-blue cursor-pointer mb-2 mt-2" @click="downloadAll">
                            Download All
                            <fa :icon="['fal', 'arrow-to-bottom']" class="ml-2" />
                        </div>
                    </div>

                    <a :href="linkDoc" :download="nameDoc" ref="linkDoc"></a>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import FileInput from '~/components/FileInput';

export default {
    middleware: 'auth',

    name: 'CustomsDeclarationsId',

    components: { FileInput },
    
    data() {
        return {
            documents: [],
            linkDoc: '',
            nameDoc: '',
            loadingUpload: false,
            uuid: 'f8a3f640-af54-11ec-9b65-d72263b736a3'
        }
    },

    computed: {
        adminRoute() {
            return this.$route.name.indexOf('admin') !== -1;
        },
    },

    methods: {
        async setDocs() {
            this.loadingUpload = true;

            const {data} = await axios.get(`${this.$apiUrl.documents.base}/?filter[search]=${this.uuid}`);

            this.documents = data.data;

            this.loadingUpload = false;
        },

         async addDocument(value) {
            this.loadingUpload = true;

            const data = {
                name: value.name,
                description: '',
                type: 'Invoice',
                tags:  null,
                media: value.id,
                'objects[0][object]': 'Shipment',
                'objects[0][reference]': this.uuid,
            }

            const result = this.objectToFormData(data);

            try {
                await axios.post(this.$apiUrl.documents.base, result);

                this.$snotify.success(`Success`);

                await this.setDocs();
            } catch(error) {
                this.$snotify.success(error?.response);
            } finally {
                this.loadingUpload = false;
            }
        },

        async deleteDocument(target) {
            const url = `${this.$apiUrl.documents.base}/${target.id}`;

            await axios.delete(url).then(() => {
                this.$snotify.success(`Success`);

                this.setDocs()

                this.loadingUpload = false;
            });
        },

        downloadAll() {
            this.documents.forEach(async item => {
                await this.download(item);
            });
        },

        download(value) {
            this.linkDoc = value.media.url;
            this.nameDoc = value.name;

            setTimeout(() => {
                this.$refs.linkDoc.click();
            }, 250)
        },
    },

    async created() {
        await this.setDocs();
    }
}
</script>

<style lang="scss">
.declarations-id {
    &__right-content  {
        max-width: calc(100% - 328px);
    }

    .card-body {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
    }

    .card-content {
        padding: 16px 24px;
        width: 100%;
    }
}
</style>